import axios from "axios";

const token = localStorage.getItem("token");

const configuracion = {
  headers: {
    Authorization: `Bearer ` + token,
  },
};

//AWS
// const api = axios.create({
//   baseURL: "http://localhost:3001/api/",
// });
// //const prospeccionApi = axios.create({
// //  baseURL: "http://localhost:4001/api",
// //});

export const getPassengerVehiculs = async (_id) => {
  console.log(_id);
  const { data } = await axios.get(
    "vehiculoPersonal/list_passenger_vehicles/" + _id,
    configuracion
  );
  return data;
};

export const updatePassengerAccess = async (datos) => {
  const { data } = await axios.put(
    "vehiculoPersonal/autorizar_acceso/",
    datos,
    configuracion
  );
  return data;
};
export const updateVehicleAccess = async (datos) => {
  const { data } = await axios.put(
    "vehiculoPersonal/autorizar_acceso_vehiculo/",
    datos,
    configuracion
  );
  return data;
};

export const guardarCambiosTarifas = async (datos) => {
  const { data } = await axios.post(
    "solicitudUnica/guardar_cambios_tarifas/",
    datos,
    configuracion
  );
  return data;
};

export const obtenerTarifa = async () => {
  const { data } = await axios.get(
    "solicitudUnica/obtener_tarifas/",
    configuracion
  );
  return data;
};

export const reprogramarSolicitud = async (datos) => {
  const { data } = await axios.put(
    "solicitudUnica/reprogramar_solicitud/",
    datos,
    configuracion
  );
  return data;
};

export const getFoliosSolicitudes = async () => {
  const { data } = await axios.get(
    "solicitudUnica/obtener_folios",
    configuracion
  );
  return data;
};

export const insertBitacoraEmbarcación = async (datos) => {
  const data = await axios.post(
    "bitacoraEmbarcacion/add",
    datos,
    configuracion
  );
  return data;
};

export const listAccesoFolio = async (folio) => {
  const { data } = await axios.get(
    "bitacoraEmbarcacion/list/" + folio,
    configuracion
  );
  return data;
};

export const listBitacora = async () => {
  const { data } = await axios.get(
    "bitacoraEmbarcacion/list_bitacora",
    configuracion
  );
  return data;
};

export const forgotPassword = async (email) => {
  const result = await axios.post("usuario/request-reset", { email });
  return result;
};

export const resetPassword = async (token, password) => {
  const result = await axios.post("usuario/reset-password", {
    token,
    password,
  });
  return result;
};

//TICKETS
export const crearTicket = async (datos) => {
  const { data } = await axios.post("tickets/add", datos, configuracion);
  return data;
};

export const getTickets = async () => {
  const { data } = await axios.get("tickets/", configuracion);
  return data;
};
export const allTickets = async () => {
  const { data } = await axios.get("tickets/all_tickets", configuracion);
  return data;
};

export const getTicketsEstatus = async (estatus) => {
  const { data } = await axios.post(
    "tickets/tickets",
    { estatus },
    configuracion
  );
  return data;
};

export const addLogs = async (datos, _id) => {
  const { data } = await axios.post(
    "tickets/add_logs/" + _id,
    datos,
    configuracion
  );
  return data;
};

export const updateEstatus = async (estatus, _id) => {
  const { data } = await axios.post(
    "tickets/update_estatus/" + _id,
    { estatus },
    configuracion
  );
  return data;
};
export const getTicketsId = async (_id) => {
  const { data } = await axios.get(
    "tickets/get_ticket_id/" + _id,
    configuracion
  );
  return data;
};
